
import { computed, defineComponent } from "vue";

import { useClinicsStore } from "@/store/clinics/ClinicsStore";
import ClinicSettingsFields from "@/components/clinics/settings/ClinicSettingsFields.vue";

export default defineComponent({
  components: { ClinicSettingsFields },
  setup() {
    const clinicsStore = useClinicsStore();
    const clinicsState = clinicsStore.getState();
    const activeClinicUID = computed(() => clinicsState.activeClinicUID);
    const activeClinic = computed(() =>
      clinicsState.clinics.find(
        (clinic) => clinic.uid === activeClinicUID.value
      )
    );
    const haveSelectedClinic = computed(() => !!activeClinic.value);
    return { haveSelectedClinic, activeClinic };
  },
});
