
import {defineComponent, ref, watch} from 'vue'
import {Logo} from "@/types";


export default defineComponent({
  name: "AddImageForm",
  emits: {
    onLogo: (logo: unknown) => !!logo
  },
  props: {
    haveLogo: {
      type: Boolean,
      default: false
    }
  },
  setup(_, {emit}) {

    const validLogoTypes = [
      "image/apng",
      "image/bmp",
      "image/gif",
      "image/jpeg",
      "image/pjpeg",
      "image/png",
      "image/svg+xml",
      "image/tiff",
      "image/webp",
      "image/x-icon",
    ]

    const selectedLogo = ref<Logo | undefined>(undefined)

    const validLogoType = (file: File) => {
      return validLogoTypes.includes(file.type)
    }

    const logoSize = (size: number) => {
      if (size < 1024) {
        return size + "bytes";
      } else if (size >= 1024 && size < 1048576) {
        return (size / 1024).toFixed(1) + "KB";
      } else {
        return (size / 1048576).toFixed(1) + "MB";
      }
    }

    const onLogoSelected = (e: { target: HTMLInputElement }) => {
      if (e.target.files?.length) {
        const logo = e.target.files[0]
        if (!validLogoType(logo)) console.log('Not valid logo type') // TODO: error
        else {
          selectedLogo.value = {
            id: `${logo.name}__${new Date().getTime()}`,
            name: logo.name,
            size: logoSize(logo.size),
            meta: logo,
          }
        }
      }
    }

    watch(() => selectedLogo.value, () => emit('onLogo', selectedLogo.value))
    return {onLogoSelected, selectedLogo}
  }
})
