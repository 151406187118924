
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  watchEffect,
} from "vue";
import InputField from "@/components/utils/input/InputField.vue";

import {ClinicDto} from "@/shared/types";
import AddImageForm from "@/components/forms/AddImageForm.vue";
import {Logo} from "@/types";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";

export default defineComponent({
  name: "ClinicSettingsFields",
  components: {AddImageForm, InputField},
  props: {
    activeClinic: {
      type: Object as PropType<ClinicDto>,
      required: true,
    },
  },
  setup(props) {
    const clinicsStore = useClinicsStore();

    const name = ref<string | undefined>(undefined);
    const radiationPracticeLicenseHolder = ref<string | undefined>(undefined)
    const licenseNos = ref<string[]>([]);
    const licenceLocation = ref<string | undefined>(undefined);
    const rapporteurName = ref<string | undefined>(undefined);
    const rapporteurEmail = ref<string | undefined>(undefined);
    const rapporteurPhone = ref<string | undefined>(undefined);
    const accountantEmail = ref<string | undefined>(undefined);
    const logo = ref<string | undefined>(undefined);

    onBeforeMount(() => {
      name.value = props.activeClinic.name;
      radiationPracticeLicenseHolder.value = props.activeClinic.radiationPracticeLicenseHolder;
      licenseNos.value = [...props.activeClinic.licenseNos];
    });

    const initFields = (clinic: ClinicDto) => {
      name.value = clinic.name;
      radiationPracticeLicenseHolder.value = clinic.radiationPracticeLicenseHolder;
      licenseNos.value = [...clinic.licenseNos];
      licenceLocation.value = clinic.licenceLocation;
      rapporteurName.value = clinic.rapporteurName;
      rapporteurEmail.value = clinic.rapporteurEmail;
      rapporteurPhone.value = clinic.rapporteurPhone;
      accountantEmail.value = clinic.accountantEmail;
      logo.value = clinic.logo;
    };

    watchEffect(() => initFields(props.activeClinic));

    const removeLicenceNo = (index: number) =>
        licenseNos.value.splice(index, 1);
    const showAddNewLicenceNoButton = computed(
        () =>
            !!licenseNos.value.length &&
            !!licenseNos.value[licenseNos.value.length - 1].length
    );
    const addNewLicenceNo = () =>
        showAddNewLicenceNoButton.value ? licenseNos.value.push("") : undefined;

    const onLogo = (payload: Logo) => {
      const reader = new FileReader();
      reader.onload = (e) => (logo.value = e.target?.result as string);
      reader.readAsDataURL(payload.meta);
    };

    const onSave = async () => {
      if (
          typeof name.value === "string" &&
          typeof radiationPracticeLicenseHolder.value == "string" &&
          licenseNos.value &&
          licenseNos.value.length
      ) {
        await clinicsStore.editClinicSettings({
            uid: props.activeClinic.uid,
            managerUID: props.activeClinic.managerUID,
            usersCount: props.activeClinic.usersCount,
            active: props.activeClinic.active,
            workersUID: props.activeClinic.workersUID,
            pastWorkersUID: props.activeClinic.pastWorkersUID ?? [],
            cabinetsUID: props.activeClinic.cabinetsUID,
            name: name.value,
            radiationPracticeLicenseHolder: radiationPracticeLicenseHolder.value,
            licenseNos: licenseNos.value,
            licenceLocation: licenceLocation.value,
            rapporteurName: rapporteurName.value,
            rapporteurEmail: rapporteurEmail.value,
            rapporteurPhone: rapporteurPhone.value,
            accountantEmail: accountantEmail.value,
            logo: logo.value,
            alternativeManagerUID: props.activeClinic.alternativeManagerUID
        });
      }
    };

    return {
      name,
      licenseNos,
      licenceLocation,
      rapporteurName,
      rapporteurEmail,
      rapporteurPhone,
      accountantEmail,
      removeLicenceNo,
      showAddNewLicenceNoButton,
      addNewLicenceNo,
      onLogo,
      logo,
      onSave,
      radiationPracticeLicenseHolder
    };
  },
});
